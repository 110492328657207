export const PermissionsCodes = {
  CAPABILITY_ROLE_VIEW: "capability_role_view",
  CAPABILITY_ROLE_CREATE: "capability_role_create",
  CAPABILITY_ROLE_UPDATE: "capability_role_update",
  USER_PERSON_VIEW: "user_person_view",
  USER_PERSON_CREATE: "user_person_create",
  USER_PERSON_UPDATE: "user_person_update",
  ENTITY_VARIABLE_VIEW: "entity_variable_view",
  ENTITY_VARIABLE_CREATE: "entity_variable_create",
  ENTITY_VARIABLE_UPDATE: "entity_variable_update",
  ENTITY_VARIABLE_DELETE: "entity_variable_delete",
  TAXONOMY_CATEGORY_VIEW: "taxonomy_category_view",
  TAXONOMY_CATEGORY_CREATE: "taxonomy_category_create",
  TAXONOMY_CATEGORY_UPDATE: "taxonomy_category_update",
  MANAGEMENT_CONTRACT_VIEW: "management_contract_view",
  MANAGEMENT_CONTRACT_CREATE: "management_contract_create",
  MANAGEMENT_CONTRACT_UPDATE: "management_contract_update",
  MANAGEMENT_FILE_PUBLIC_VIEW: "management_file_public_view",
  MANAGEMENT_FILE_PUBLIC_CREATE: "management_file_public_create",
  MANAGEMENT_FILE_PUBLIC_UPDATE: "management_file_public_update",
  MANAGEMENT_CONTRACT_GENERATED_VIEW: "many_contract_customer_view",
  UTILITY_FAQ_VIEW: "utility_faq_view",
  UTILITY_FAQ_CREATE: "utility_faq_create",
  UTILITY_FAQ_UPDATE: "utility_faq_update",
  UTILITY_FAQ_DELETE: "utility_faq_delete",
  USER_CUSTOMER_VIEW: "user_customer_view",
  USER_CUSTOMER_CREATE: "user_customer_create",
  USER_CUSTOMER_UPDATE: "user_customer_update",
}