export class HeaderDataRepository {
  public title = '';
  public breadcrumbRoute: string[] = [];
  public urlToBack = '';

  changeMenuData(title: string, routes: string[], urlToBack: string = '') {
    this.title = title;
    this.breadcrumbRoute = routes;
    this.urlToBack = urlToBack;
  }
}
